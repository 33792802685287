import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import { rhythm } from "utils/typography";
import LocalizedLink from "components/localizedLink";
import { LocaleContext } from "context/locale-context";
import { ThemeContext } from "context/theme-context";
import Layout from "components/layout";
import PostHeader from "components/PostHeader";
import { mq } from "utils/helper";
import { css } from "@emotion/react";
import useTranslations from "hooks/useTranslations";
import "style/pagination.css";
import "style/filter-bar.css";
import { contentDecode } from "utils/content-decode";
import TableOfContents from "components/TableOfContents";

const coupfiles = ({ location, data }) => {
  const post = data.mdx;
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();
  const arPost = post.localizations.data.map((a) => a.attributes)[0];
  let content = post.content.data.content;
  content = locale === "en" ? content : arPost.content;

  const firstBox = css({
    "ul:first-of-type": {
      border: `6px solid ${colors.dark}`,
      boxShadow: "-5px 5px 12px 0 rgba(0,0,0,0.25)",
      padding: `${rhythm(1)} ${rhythm(2)}`,
      "& li": {
        listStyle: "none",
        margin: 0,
        "&::before": {
          content: '"•"',
          color: colors.primary,
          display: "inline-block",
          width: "0.8em",
          fontSize: "2em",
        },
      },
      [mq[0]]: {
        paddingLeft: rhythm(1),
        paddingRight: rhythm(1),
        marginLeft: 0,
      },
    },
  });
  const [isMobile, setIsMobile] = useState(false);

  const isPress = post.type === "press";
  const isMethod = post.type === "methodology";
  return (
    <div>
      <Layout>
        <PostHeader post={data.mdx} arPost={arPost} location={location} />
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "25% 50% 25%",
            paddingTop: rhythm(2),
            direction: isRTL ? "rtl" : "ltr",
            [mq[2]]: {
              display: "block",
              paddingTop: 0,
              marginRight: rhythm(1.3),
              marginLeft: rhythm(1.3),
            },
          }}
        >
          {isMobile || (
            <TableOfContents post={data.mdx} arPost={arPost} tr={tr} />
          )}
          <div
            css={[
              {
                maxWidth: rhythm(32.25),
                marginBottom: rhythm(4),
                justifySelf: "center",
              },
              isPress || isMethod || firstBox,
            ]}
          >
            {contentDecode(content)}
          </div>
          {isMobile || <div></div>}
        </div>
      </Layout>
    </div>
  );
};

export default coupfiles;

export const pageQuery = graphql`
  query StrapiCoupFile {
    mdx: strapiDataset {
      title
      image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      date
      desc
      locale
      content {
        data {
          content
        }
      }
      localizations {
        data {
          attributes {
            title
            date
            desc
            content
            locale
          }
        }
      }
    }
  }
`;
